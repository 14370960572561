.nav-item  .nav-link.is-active{
    color:#4ec9ee!important;
}

.nav-item  .nav-link:hover{
    color:#4ec9ee!important;
}
.nav-item  .nav-link{
    color:#aaaaaa!important;
}

.header{
    box-shadow: 0 2px 4px -2px rgb(41, 50, 44);
}

@media only screen and (max-width: 600px) {
    .second-logo {
        margin-left: -15px;
    }
  }